<template>
  <div
    :class="$style.login"
    :style="{ backgroundImage: 'url(' + official.login_bg + ')' }"
  >
    <div :class="$style.box">
      <a-row>
        <a-col
          :class="$style.img"
          :span="12"
          :style="{ backgroundImage: 'url(' + official.login_theme + ')' }"
        />
        <!-- <a-col :class="$style.img" :span="12" /> -->
        <a-col :span="12">
          <div :class="$style.logo">
            <!-- logo todo -->
            <img :src="official.rectangle_logo" alt="" />
          </div>
          <BjForm
            v-if="loginState === 'login'"
            ref="login"
            :class="$style.formlogin"
            @submit="loginIn"
          >
            <div :class="$style.title">登录</div>
            <bj-input
              v-model="loginData.username"
              rules="required|phoneEmail"
              placeholder="请输入邮箱或者手机号码"
              label="登录账号"
            />
            <bj-input
              v-model="loginData.password"
              type="password"
              rules="required"
              placeholder="请输入登录密码"
              label="登录密码"
            >
              <span slot="suffix" class="tag" @click="back('forget')">忘记密码</span>
            </bj-input>
            <bj-button
              type="primary"
              class="lg"
              :loading="loading"
              block
              html-type="submit"
              >{{ loading ? "登录中..." : "立即登录" }}</bj-button
            >
            <!-- <a-row :class="$style.foot">
              <a-col span="12">
                <span class="cursor" @click="scan()"
                  ><i class="ri-wechat-fill mr-4" />扫码登录</span
                >
              </a-col>
              <a-col class="tright" span="12">
                <span class="cursor" @click="loginState = 'register'"
                  >立即注册
                  <i class="ri-arrow-right-s-line" />
                </span>
              </a-col>
            </a-row> -->
          </BjForm>

          <BjForm
            v-if="loginState === 'register'"
            ref="register"
            :class="$style.formlogin"
            @submit="register"
          >
            <div :class="$style.title">
              <span v-if="bindData.avatar" @click="back('bind')">
                <i :class="$style.back" class="ri-arrow-left-line" />
              </span>
              注册 {{ bindData.avatar ? "账号" : "" }}
              <img
                v-if="bindData.avatar"
                :class="$style.avatar"
                :src="bindData.avatar"
                alt=""
              />
            </div>
            <bj-input
              v-model="registerData.username"
              rules="required|phoneEmail"
              placeholder="请输入你要注册的邮箱或手机号码"
              label="登录账号"
            />
            <a-tooltip
              placement="bottom"
              title="密码至少含有6位字符（包含字母、数字、符号两种以上类型的组合形式）"
            >
              <bj-input
                v-model="registerData.password"
                :type="passwordType"
                rules="required"
                placeholder="请输入你要注册的登录密码"
                label="登录密码"
                @keydown.native="keydown($event)"
                @keyup="
                  registerData.password = registerData.password.replace(
                    /[\u4e00-\u9fa5]/g,
                    ''
                  )
                "
              >
                <span slot="suffix" @click="passwordChange()"
                  ><i
                    class="ri-eye-line primary"
                    :style="passwordType === 'password' ? 'color: #bbb' : ''"
                    :class="[$style.icon, $style.eye]"
                /></span>
              </bj-input>
            </a-tooltip>
            <bj-button
              type="primary"
              class="lg"
              :disabled="!checked"
              :loading="loading"
              block
              html-type="submit"
              >{{ loading ? "请稍后" : "立即注册" }}</bj-button
            >
            <a-row :class="$style.foot">
              <a-col span="16">
                <a-checkbox
                  class="pull-left"
                  :checked="checked"
                  :class="$style.tip"
                  @click="checked = !checked"
                />
                <span class="pull-left" :class="$style.default">我同意</span>
                <span class="cursor primary pull-left" @click="toProtocol()"
                  >相关协议和条款</span
                >
              </a-col>
              <a-col class="tright" span="8">
                <span class="cursor" @click="loginState = 'login'"
                  >立即登录
                  <i class="ri-arrow-right-s-line" />
                </span>
              </a-col>
            </a-row>
          </BjForm>

          <BjForm
            v-if="loginState === 'code'"
            ref="code"
            :class="$style.formlogin"
            @submit="registerCode"
          >
            <div :class="$style.title">
              <span @click="back('register')"
                ><i :class="$style.back" class="ri-arrow-left-line"
              /></span>
              验证码
            </div>
            <BjCard :title="codeTips" />
            <ValidCodeInput ref="codeInput" @change="validChange" />
            <div>
              <span
                v-if="!forgetTimer"
                class="cursor pull-right mb-30 tag"
                @click="refreshCode()"
                >{{ forgetTimer === 0 ? "没收到？重新发送" : "获取验证码" }}</span
              >
              <span v-else class="pull-right mb-30 tag"
                >{{ forgetTimer }}秒后可重新获取</span
              >
            </div>
            <bj-button
              :disabled="allowRegister"
              class="lg"
              :loading="loading"
              type="primary"
              block
              html-type="submit"
              >{{ loading ? "正在完成注册" : "下一步" }}</bj-button
            >
          </BjForm>

          <BjForm
            v-if="loginState === 'forget'"
            ref="forget"
            :class="$style.formlogin"
            @submit="forget"
          >
            <div :class="$style.title">
              <span @click="back('login')"
                ><i :class="$style.back" class="ri-arrow-left-line"
              /></span>
              忘记密码
            </div>
            <BjCard class="m-30" :title="forgetTips" />
            <bj-input
              v-model="forgetData.username"
              placeholder="请输入邮箱或手机号码"
              label="登录账号"
            >
              <span slot="suffix" class="tag" @click="getValidCode()">{{
                forgetTimer
                  ? forgetTimer + "秒可重新获取"
                  : forgetTimer === 0
                  ? "没收到？重新发送"
                  : "获取验证码"
              }}</span>
            </bj-input>
            <ValidCodeInput ref="forgetCode" @change="validForgetChange" />
            <bj-button
              :disabled="allowForget"
              class="mt-20 lg"
              type="primary"
              :loading="loading"
              block
              html-type="submit"
            >
              {{ loading ? "请稍后" : "设置新的密码" }}
            </bj-button>
          </BjForm>

          <BjForm
            v-if="loginState === 'reset'"
            ref="reset"
            :class="$style.formlogin"
            @submit="forgetPassword"
          >
            <div :class="$style.title">
              <span @click="back('forget')"
                ><i class="ri-arrow-left-line" :class="$style.back"
              /></span>
              设置新的密码
            </div>
            <BjCard class="m-30" :title="resetTips" />
            <a-tooltip
              placement="bottom"
              title="密码至少含有6位字符（包含字母、数字、符号两种以上类型的组合形式）"
            >
              <bj-input
                v-model="forgetData.password"
                :type="passwordType"
                placeholder="请输入你要设置的新的密码 "
                label="新的密码"
              >
                <span slot="suffix" @click="passwordChange()"
                  ><i
                    :class="$style.eye"
                    :style="passwordType === 'password' ? 'color: #bbb' : ''"
                    class="ri-eye-line primary"
                /></span>
              </bj-input>
            </a-tooltip>
            <bj-button
              type="primary"
              :loading="loading"
              class="lg"
              block
              html-type="submit"
              >{{ loading ? "正在重置密码…" : "立即重置密码" }}</bj-button
            >
          </BjForm>

          <div v-if="loginState === 'forgetSuccess'" :class="$style.formlogin">
            <div :class="$style.title">
              <span><i class="ri-arrow-left-line" :class="$style.noBack" /></span>
              密码重置成功
            </div>
            <BjCard class="m-30" :title="forgetSuceessTips" />
            <div :class="$style.forgetIcon" />
            <bj-button type="primary" class="lg" block @click="back('login')"
              >返回登录页面</bj-button
            >
          </div>

          <BjForm v-if="loginState === 'scan'" ref="scan" :class="$style.formlogin">
            <div :class="$style.title">
              <span @click="back('login')"
                ><i :class="$style.back" class="ri-arrow-left-line"
              /></span>
              微信登录
              <a-popover placement="bottomRight" arrow-point-at-center title="说明">
                <template slot="content">
                  <p>1、首次扫码登录可能会需要先关注微信公众号。</p>
                  <p>
                    2、如果扫码之后页面没有跳转动作，请点击
                    <span class="primary cursor" @click="scan()">刷新二维码</span>
                    后重新扫码。
                  </p>
                </template>
                <i class="ri-question-line pull-right" :class="$style.question" />
              </a-popover>
            </div>
            <div :class="$style.rqCode">
              <div v-if="RQCode" :class="$style.rqCodeBox">
                <img :src="RQCode.url" />
                <div v-if="!rqCodeFinish" :class="$style.rqCodeItem">
                  <i v-if="!wxTips" class="ri-check-line" />
                  <i v-else class="ri-restart-line" @click="scan()" />
                </div>
                <div v-if="rqCodeTimeout" :class="$style.rqCodeItem">
                  <p :class="$style.rqCodeItemTip">二维码已过期</p>
                  <div :class="$style.rqCodeItemTimeout" @click="scan()">
                    <i class="ri-restart-line" />
                  </div>
                </div>
              </div>
              <a-spin v-else :class="$style.spin" />
              <p v-if="rqCodeState" :class="$style.rqtip">
                <i class="ri-qr-scan-2-line" /> &nbsp; 请使用微信扫码登录
              </p>
              <p v-else :class="$style.rqtip">
                <i class="ri-emotion-unhappy-line" />
                &nbsp; 扫码失败，请刷新重新获取…
              </p>
            </div>
          </BjForm>

          <BjForm
            v-if="loginState === 'bind'"
            ref="bind"
            :class="$style.formlogin"
            @submit="bindIn"
          >
            <div :class="$style.title">
              <span @click="back('login')"
                ><i class="ri-arrow-left-line" :class="$style.back"
              /></span>
              绑定已有账号 <img :class="$style.avatar" :src="bindData.avatar" alt="" />
            </div>
            <bj-input
              v-model="bindData.username"
              placeholder="请输入邮箱或者手机号码"
              label="登录账号"
            />
            <bj-input
              v-model="bindData.password"
              type="password"
              rules="required"
              placeholder="请输入登录密码"
              label="登录密码"
            >
              <span slot="suffix" class="tag" @click="back('forget')">忘记密码</span>
            </bj-input>
            <bj-button
              type="primary"
              class="lg"
              :loading="loading"
              block
              html-type="submit"
              >{{ loading ? "登录中..." : "绑定并登录" }}</bj-button
            >
            <p class="tip text-right mt-20">
              如果你还没有账号，请
              <span class="primary cursor" @click="back('register')"
                >立即注册
                <i
                  style="font-size: 18px; vertical-align: -4px"
                  class="ri-arrow-right-s-line"
                />
              </span>
            </p>
          </BjForm>
        </a-col>
      </a-row>
    </div>
    <div :class="$style.footer">
      <!-- <a :href="official.web_site" class="primary" target="_blank">{{ official.official_name }}</a
      >提供技术支持
      <span class="ml-5">
        ©{{ year }}{{ official.principal_name }}
        <a class="default-link ml-5" href="https://beian.miit.gov.cn/" target="_blank">{{ official.icp_no }}</a>
      </span> -->
      运城文旅云提供技术支持 ©2024运城市文化和旅游局 晋ICP备2024036113号-2
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import { ValidCodeInput } from "@/components";
import { loginService } from "@/service/login";

const service = new loginService();

export default {
  name: "login",
  components: { ValidCodeInput },
  data() {
    return {
      loginData: {
        username: "",
        password: "",
      },
      registerData: {
        username: null,
        password: null,
        is_agreement: 1,
        third_party_key: null,
      },
      forgetData: {
        username: null,
        code: null,
        password: null,
      },
      bindData: {
        username: "",
        password: "",
        third_party_key: null,
        avatar: null,
      },
      loginState: "login", // 显示状态
      loading: false,
      passwordType: "password",
      codeTips: "我们已经向你的邮箱或手机号码发送了验证码，请注意查收并填写在下方。",
      forgetTips:
        "请在下方输入你的邮箱或手机号码，我们会将带有重置密码的验证码信息发送给你。",
      resetTips:
        "请输入你要设置的新的密码，密码请输入至少6位字符（包含字母加数字或符号的组合）。",
      forgetSuceessTips:
        "你的密码已经重置成功，请返回登录页面重新登录。建议将账号与微信绑定，避免忘记密码的烦恼。",
      allowRegister: true,
      allowForget: true,
      checked: true,
      RQCode: null,
      rqCodeState: true,
      rqCodeFinish: true,
      wxTips: null,
      timer: null,
      forgetTimer: null, // 忘记密码倒计时
      rqCodeTimeout: false, // 二维码5分钟过期状态
      // forgetState: false, // 重置密码显示状态
      year: 2021,
      valid_key: null,
    };
  },
  computed: {
    ...mapState(["official"]),
  },
  watch: {
    loginState(val) {
      let type = "";
      switch (val) {
        case "login":
          type = "sign";
          break;
        case "register":
          type = "signup";
          break;
        case "forget":
          type = "forgetpwd";
          break;
        case "scan":
          type = "wechat";
          break;
      }
      this.$router.push({
        name: "login",
        query: {
          type: type,
        },
      });
    },
  },
  created() {
    this.year = new Date().getFullYear();
    if (this.$route.query) {
      switch (this.$route.query.type) {
        case "signup":
          this.loginState = "register";
          break;
        case "forgetpwd":
          this.loginState = "forget";
          break;
        case "wechat":
          this.loginState = "scan";
          break;
      }
    }
  },
  methods: {
    ...mapMutations(["LOGIN"]),
    async loginIn() {
      this.$refs.login.validate().then(async (e) => {
        if (!e) return;
        try {
          this.loading = true;
          const { data } = await service.login(this.loginData);
          if (data.token) {
            this.LOGIN(data);
          }
        } catch (e) {
          this.loading = false;
        } finally {
          this.loading = false;
        }
      });
    },
    async bindIn() {
      this.$refs.bind.validate().then(async (e) => {
        if (!e) return;
        try {
          this.loading = true;
          const { data } = await service.login(this.bindData);
          if (data.token) {
            this.LOGIN(data);
          }
        } catch (e) {
          this.loading = false;
        } finally {
          this.loading = false;
        }
      });
    },
    async register() {
      this.$refs.register.validate().then(async (e) => {
        if (!e) return;
        if (
          /^([a-zA-Z])+$/.test(this.registerData.password) ||
          /^\d+$/.test(this.registerData.password) ||
          /^\W+$/.test(this.registerData.password)
        ) {
          this.$message.error(
            "你输入的密码格式不正确， 请输入至少6位字符（包含字母、数字、符号两种以上类型的组合形式）。"
          );
          return false;
        }
        if (this.registerData.password.length < 6) {
          this.$message.error(
            "你输入的密码格式不正确， 请输入至少6位字符（包含字母、数字、符号两种以上类型的组合形式）。"
          );
          return false;
        }
        try {
          this.loading = true;
          await this.refreshCode();
          this.loginState = "code";
        } catch (e) {
        } finally {
          this.loading = false;
        }
      });
    },
    async refreshCode() {
      let timer = null;
      try {
        await service.getValidCode(this.registerData);
        this.$message.success(
          "带有验证码的信息已经通过你填写的邮箱或手机号码发送给你，请注意查收。"
        );
        this.forgetTimer = 60;
        timer = setInterval(() => {
          if (this.forgetTimer !== 0) {
            this.forgetTimer--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      } catch (e) {
        throw new Error(e);
      }
    },
    async registerCode() {
      try {
        this.loading = true;
        const { data } = await service.register({
          ...this.registerData,
          code: this.$refs.codeInput.code.join(""),
        });
        if (data.token) {
          this.LOGIN(data);
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    async getValidCode() {
      if (this.forgetTimer) {
        return;
      }
      let timer = null;
      try {
        await service.getValidCode({
          username: this.forgetData.username,
          template: "forget_password",
          is_valid_register: 0,
        });
        this.forgetTimer = 60;
        timer = setInterval(() => {
          if (this.forgetTimer !== 0) {
            this.forgetTimer--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
        this.$message.success(
          "带有验证码的信息已经通过你填写的邮箱或手机号码发送给你，请注意查收。"
        );
      } catch (e) {}
    },
    async forget() {
      try {
        this.loading = true;
        this.forgetData.code = this.$refs.forgetCode.code.join("");
        const { data } = await service.forgetCheck({
          username: this.forgetData.username,
          code: this.forgetData.code,
        });
        this.loginState = "reset";
        this.valid_key = data.valid_key;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    validChange(type) {
      this.allowRegister = !type;
    },
    validForgetChange(type) {
      this.allowForget = !type;
    },
    passwordChange() {
      this.passwordType = this.passwordType === "text" ? "password" : "text";
    },
    back(state) {
      this.loginState = state;
    },
    async scan() {
      try {
        this.rqCodeTimeout = false;
        this.RQCode = null;
        this.timer = null;
        this.loginState = "scan";
        const { data } = await service.loginWx({ platform_type: "wx" });
        this.RQCode = data;
        this.rqCodeState = true;
        this.rqCodeFinish = true;
        this.wxTips = null;
        this.timer = setInterval(() => {
          this.checkWx();
        }, 2000);
        setTimeout(() => {
          this.rqCodeTimeout = true;
          clearInterval(this.timer);
        }, 1000 * 60 * 1);
      } catch (e) {}
    },
    async checkWx() {
      const { data } = await service.checkWx({
        platform_type: "wx",
        login_key: this.RQCode.login_key || "",
      });
      if (this.loginState !== "scan") {
        clearInterval(this.timer);
        return;
      }
      if (data.status === "done") {
        if (data.is_bind) {
          this.$message.success("扫码登录成功");
          clearInterval(this.timer);
          this.rqCodeFinish = false;
          setTimeout(() => {
            this.LOGIN(data);
          }, 2000);
        } else {
          this.bindData.third_party_key = data.third_party_key;
          this.registerData.third_party_key = data.third_party_key;
          this.bindData.avatar = data.avatar;
          this.loginState = "bind";
        }
      }
      if (data.status === "error") {
        this.$message.error(data.message);
        this.wxTips = data.message;
        this.rqCodeState = false;
        this.rqCodeFinish = false;
        clearInterval(this.timer);
      }
    },
    async forgetPassword() {
      try {
        await service.forget({
          ...this.forgetData,
          valid_key: this.valid_key,
        });
        this.loginState = "forgetSuccess";
        this.$message.success("密码重置成功");
      } catch (e) {}
    },
    keydown(e) {
      if (e.keyCode == 32) {
        e.returnValue = false;
      }
    },
    toProtocol() {
      let routeUrl = this.$router.resolve({
        path: "/protocol",
        query: { type: 1 },
      });
      window.open(routeUrl.href);
    },
  },
};
</script>

<style lang="less" module>
@oss: "https://minio.ycwly.com/cultural-cloud";

.login {
  position: relative;
  height: 100vh;
  min-height: 800px;
  overflow: auto;
  background-color: #f5fcfd;
  // background-image: url('@{oss}/manage/media/assets/login_bg.jpg');
  background-position: 50%;
  background-size: 2048px 1063px;

  :global {
    .ant-input {
      padding: 0;
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0;
    }

    .ant-input:hover {
      border-color: @primary-color;
    }

    .ant-input:focus {
      border-top: none;
      border-right: none;
      border-bottom: 1px solid @primary-color;
      border-left: none;
      box-shadow: none;
    }

    .ant-form-item-required::before {
      display: none;
    }

    .has-error .ant-input,
    .has-error .ant-input:hover {
      border-color: #ff2d55 !important;
    }

    .has-error .ant-input-affix-wrapper .ant-input:focus {
      box-shadow: none;
    }

    .ant-popover-inner-content {
      padding: 10px;
    }
  }
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 880px;
  height: 600px;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);

  .img {
    height: 600px;
    background-image: url("@{oss}/manage/media/assets/login_banner.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .logo {
    padding: 60px 0;
    text-align: center;

    img {
      // width: 150px;
      height: 40px;
    }
  }

  .formlogin {
    padding: 0 60px;
  }

  .foot {
    margin-top: 10px;
    color: @primary-color;
    font-size: 12px;
    line-height: 27px;

    i {
      font-size: 18px;
      vertical-align: -4px;
    }

    :global {
      .ant-checkbox-wrapper {
        margin-right: 5px;
        font-size: 12px;
      }
    }

    .default {
      color: #bfbfbf;
    }
  }

  .title {
    margin-bottom: 20px;
    color: #000;
    font-size: 18px;
  }
}

.avatar {
  float: right;
  width: 27px;
  height: 27px;
  border-radius: 100%;
}

.footer {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  color: #5c5c5c;
  font-size: 12px;
  text-align: center;
}

.icon {
  color: @primary-color;
  font-size: 18px;
}

.primary {
  color: @primary-color;
}

.tip {
  color: #bfbfbf;
}

.rq-code {
  padding: 20px 0 50px;
  color: #000;
  text-align: center;
  /* stylelint-disable-next-line no-descending-specificity */
  img {
    width: 170px;
    height: 170px;
  }

  &-box {
    position: relative;
    display: inline-block;
    width: 170px;
    height: 170px;
  }

  &-item {
    position: absolute;
    top: 0;
    width: 170px;
    height: 170px;
    line-height: 170px;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    /* stylelint-disable-next-line no-descending-specificity */
    .icon {
      color: #fff;
      font-size: 50px;
    }

    &-timeout {
      position: absolute;
      top: 83px;
      left: 69px;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #fff;
      border-radius: 100%;
      /* stylelint-disable-next-line no-descending-specificity */
      i {
        color: @primary-color;
        font-size: 20px;
      }
    }

    &-tip {
      position: relative;
      top: -18px;
      color: #fff;
      font-size: 14px;
      text-align: center;
    }
  }

  .spin {
    margin: 72px 0;
  }

  .rqtip {
    font-size: 12px;

    i {
      font-size: 18px;
      vertical-align: -4px;
    }
  }
}

.forget-icon {
  width: 100px;
  height: 100px;
  margin: 30px auto;
  background: url("@{oss}/manage/media/assets/reset.png") no-repeat;
  background-size: 100% 100%;
}

.eye {
  font-size: 18px;
}

.back {
  margin-right: 10px;
  font-size: 23px;
  vertical-align: -5px;
}

.back:hover {
  color: @primary-color;
}

.no-back {
  margin-right: 10px;
  color: #bbb;
  font-size: 23px;
  vertical-align: -5px;
  cursor: not-allowed;
}

.no-back:hover {
  color: #bbb;
}

.tag {
  padding: 0 5px;
  color: #5c5c5c;
  font-size: 12px;
  background: #fafafa;
  border-radius: 6px;
}

.question {
  color: #5c5c5c;
  font-size: 20px;
}
</style>
